import styled from "styled-components";

export const Container = styled.section`
  padding-top: 15%;
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  background: rgba(0, 0, 0, 0);
  .hero-text {
    & > p {
      font-size: 1.8rem;
    }
    h1 {
      font-size: 7rem;
    }

    h3 {
      color: var(--green);
      margin: 1rem 0;
    }

    p.small-resume {
      margin-bottom: 5rem;
    }
  }

  .social-media {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top: 5rem;
    padding-left: 1rem;

    img,
    span {
      font-size: 3rem;
      width: 3.5rem;
    }
  }

  .button {
    margin-top: 5rem;
    padding: 1.4rem 6rem;
  }

  .hero-image {
    img {
      max-width: 500px;
      border-radius: 50%; /* Ensure the image is circular */
    }
  }

  @media (max-width: 960px) {
    .hero-text {
      h1 {
        font-size: 5rem;
      }
    }

    .hero-image {
      display: none;
    }
  }

  @media (max-width: 600px) {
    margin-top: 35%;
  }
  @media (max-width: 480px) {
    margin-top: 45%;
  }

  .about-image {
    text-align: center;
    img {
      margin-top: 2rem;
      width: 75%;
      height: 75%; /* Ensure the height is equal to the width */
      filter: grayscale(0);
      transition: filter 0.5s;
      border-radius: 50%;
      &:hover {
        filter: grayscale(0);
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .about-image {
      max-width: 100%;
      margin-top: 4rem;
      img {
        margin-top: 2rem;
        width: 100%;
        height: 100%; /* Ensure the height is equal to the width */
        filter: grayscale(0);
        transition: filter 0.5s;
        border-radius: 50%;
        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }

  @media (max-width: 960px) {
    .about-image {
      display: flex;
      max-width: 100%;
      img {
        margin-top: 2rem;
        width: 100%;
        height: 100%; /* Ensure the height is equal to the width */
        filter: grayscale(0);
        transition: filter 0.5s;
        border-radius: 50%;
        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
`;