import { Container } from "./styles";

import reactIcon from "../../assets/react-icon.svg";
import linkedin from "../../assets/linkedin.svg";
import githubIcon from "../../assets/github.svg";
import whatsapp from "../../assets/whatsapp.svg";
// import discordIcon from '../../assets/discord.png'

export function Footer() {
	return (
		<Container className="footer">
			<a href="https://vinayaksingh.in" className="logo">
				<span>www.eudis</span>
				<span>muhangi.com</span>
			</a>
			<div>
				<p>
					This Website was made with <img src={reactIcon} alt="React" />
					{/* <span>❤️</span> */}
				</p>
			</div>

			<div className="social-media">
				<a
					href="https://www.linkedin.com/in/eudis-muhangi"
					target="_blank"
					rel="noreferrer"
				>
					<img src={linkedin} alt="Linkedin" />
				</a>
				<a href="https://github.com/eudism/" target="_blank" rel="noreferrer">
					<img src={githubIcon} alt="GitHub" />
				</a>
				<a
					href="http://wa.me/12248335849"
					target="_blank"
					rel="noreferrer"
				>
					<img src={whatsapp} alt="Whatsapp" />
				</a>
				
			</div>
		</Container>
	);
}
